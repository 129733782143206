import React, { useState } from 'react';
import { ref as databaseRef, push, update, get } from 'firebase/database';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Resizer from 'react-image-file-resizer';
import { database, storage } from '../firebaseConfig';
import './ReviewCreator.css';

const ReviewCreator = ({ pageId }) => {
    const [artistType, setArtistType] = useState('Singer/Songwriter');
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [ratings, setRatings] = useState({ quality: 0, delivery: 0, communication: 0 });
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleRating = (category, star) => {
        setRatings(prevRatings => ({ ...prevRatings, [category]: star }));
    };

    const compressImage = (file) =>
        new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                400, // Max width
                400, // Max height
                'JPEG', // Output format
                80, // Quality
                0, // Rotation
                (blob) => resolve(blob),
                'blob' // Output type
            );
        });

    const uploadFile = async (file, folder) => {
        let fileToUpload = file;

        // Compress the image if it's an image file
        if (file.type.startsWith('image/')) {
            const compressedBlob = await compressImage(file);
            fileToUpload = new File([compressedBlob], file.name, {
                type: file.type,
            });
        }

        const uniqueSuffix = `${Date.now()}-${Math.round(Math.random() * 1E9)}`;
        const fileName = `${uniqueSuffix}-${fileToUpload.name}`;
        const fileRef = storageRef(storage, `${folder}/${fileName}`);
        const uploadTask = uploadBytesResumable(fileRef, fileToUpload);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                () => {},
                (error) => {
                    console.error('Error uploading file:', error);
                    reject(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File uploaded successfully. Download URL:', downloadURL);
                        resolve(downloadURL);
                    });
                }
            );
        });
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setIsLoading(true);
            const compressedFile = await compressImage(selectedFile);
            setFile(compressedFile);
            const previewUrl = URL.createObjectURL(compressedFile);
            setPreviewUrl(previewUrl);
            setIsLoading(false);
        }
    };

    const handleSubmitReview = async () => {
        if (!pageId || !name || !file) return;

        setIsLoading(true);

        // Upload image
        const imageUrl = await uploadFile(file, 'review-images');

        // Prepare review data
        const reviewData = {
            WriterName: name,
            WriterImg: imageUrl,
            WriterType: artistType,
            ratings,
            feedback,
            timestamp: Date.now(),
        };

        const reviewsRef = databaseRef(database, `Pages/${pageId}/pageData/reviews`);
        await push(reviewsRef, reviewData);

        // Update review score and total reviews
        const reviewsSnapshot = await get(reviewsRef);
        let totalReviews = 0;
        let totalScore = 0;

        reviewsSnapshot.forEach(childSnapshot => {
            const review = childSnapshot.val();
            totalReviews += 1;
            const averageScore = (review.ratings.quality + review.ratings.delivery + review.ratings.communication) / 3;
            totalScore += averageScore;
        });

        const updatedTotalReviews = totalReviews;
        const updatedReviewScore = totalScore / updatedTotalReviews;

        await update(databaseRef(database, `Pages/${pageId}/pageData`), {
            totalReviews: updatedTotalReviews,
            reviewScore: updatedReviewScore,
        });

        setIsLoading(false);
        setShowModal(false);
    };

    return (
        <div className="review-creator-container">
            <button onClick={() => setShowModal(true)}>Add Review</button>
            {showModal && (
                <div className="review-creator-modal">
                    <div className="review-creator-modal-content">
                        <span className="review-creator-close" onClick={() => setShowModal(false)}>&times;</span>
                        <h2>Add a Review</h2>
                        <div className="review-creator-form-group">
                            <label>Artist Type</label>
                            <select value={artistType} onChange={e => setArtistType(e.target.value)}>
                                <option value="Singer/Songwriter">Singer/Songwriter</option>
                                <option value="Rapper">Rapper</option>
                                <option value="Beatmaker">Beatmaker</option>
                                <option value="Producer">Producer</option>
                                <option value="Instrumentalist">Instrumentalist</option>
                                <option value="Band">Band</option>
                                <option value="DJ">DJ</option>
                                <option value="Composer">Composer</option>
                                <option value="MC">MC</option>
                            </select>
                        </div>
                        <div className="review-creator-form-group">
                            <label>Name</label>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                        <div className="review-creator-form-group">
                            <label>Upload Image</label>
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                            {previewUrl && <img src={previewUrl} alt="Preview" className="review-creator-preview-image" />}
                            {isLoading && <div className="review-creator-spinner"></div>}
                        </div>
                        <div className="review-category-2">
                            <p>Quality of work:</p>
                            {[1, 2, 3, 4, 5].map(star => (
                                <img
                                    key={star}
                                    src={star <= ratings.quality ? './img/star_color.svg' : './img/star_black.svg'}
                                    alt={`${star} stars`}
                                    onClick={() => handleRating('quality', star)}
                                    className="review-creator-star"
                                />
                            ))}
                        </div>
                        <div className="review-category-2">
                            <p>Delivery Time:</p>
                            {[1, 2, 3, 4, 5].map(star => (
                                <img
                                    key={star}
                                    src={star <= ratings.delivery ? './img/star_color.svg' : './img/star_black.svg'}
                                    alt={`${star} stars`}
                                    onClick={() => handleRating('delivery', star)}
                                    className="review-creator-star"
                                />
                            ))}
                        </div>
                        <div className="review-category-2">
                            <p>Communication:</p>
                            {[1, 2, 3, 4, 5].map(star => (
                                <img
                                    key={star}
                                    src={star <= ratings.communication ? './img/star_color.svg' : './img/star_black.svg'}
                                    alt={`${star} stars`}
                                    onClick={() => handleRating('communication', star)}
                                    className="review-creator-star"
                                />
                            ))}
                        </div>
                        <textarea
                            value={feedback}
                            onChange={e => setFeedback(e.target.value)}
                            placeholder="Add your feedback..."
                            className="review-creator-feedback-textarea"
                        />
                        <button onClick={handleSubmitReview} disabled={isLoading}>
                            {isLoading ? 'Submitting...' : 'Submit Review'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewCreator;
