import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue, remove, update } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListArtists.css';
import EditArtistModal from './EditArtistModal';
import ArtistStats from './ArtistStats';
import ServiceStats from './ServiceStats';
import ListArtistActions from './ListArtistActions';
import ArtistAnalysis from './ArtistAnalysis';

function ListArtists() {
  const [artists, setArtists] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [paginatedArtists, setPaginatedArtists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [artistsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentArtistId, setCurrentArtistId] = useState(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [currentArtist, setCurrentArtist] = useState(null);

  const openEditModal = (artistId) => {
    setCurrentArtistId(artistId);
    setIsEditModalOpen(true);
  };

  const openActionModal = (artist) => {
    setCurrentArtist(artist);
    setIsActionModalOpen(true);
  };

  const closeActionModal = () => {
    setIsActionModalOpen(false);
    setCurrentArtist(null);
  };

  const deleteArtist = (artistId) => {
    const isConfirmed = window.confirm("Sind Sie sicher, dass Sie diesen Künstler löschen möchten?");
    if (isConfirmed) {
      const artistRef = databaseRef(database, `Artists/${artistId}`);
      remove(artistRef)
        .then(() => {
          console.log(`Künstler ${artistId} erfolgreich gelöscht.`);
        })
        .catch((error) => {
          console.error("Fehler beim Löschen des Künstlers: ", error);
        });
    }
  };

  const togglePremiumStatus = (artist) => {
    const updatedStatus = !artist.isPremium;
    const artistRef = databaseRef(database, `Artists/${artist.id}`);
    update(artistRef, { isPremium: updatedStatus })
      .then(() => {
        console.log(`Premium-Status für ${artist.artistName} erfolgreich auf ${updatedStatus ? 'Ja' : 'Nein'} gesetzt.`);
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren des Premium-Status: ", error);
      });
  };

  useEffect(() => {
    const artistsRef = databaseRef(database, 'Artists');
    onValue(artistsRef, (snapshot) => {
      const artistsData = snapshot.val();
      let artistsList = artistsData ? Object.keys(artistsData).map(key => {
        const artist = {
          id: key,
          ...artistsData[key],
        };
        
        const sessionTimestamps = Object.values(artist.sessionTimestamps || {});
        const sessionCount = sessionTimestamps.length;
        const firstSessionTimestamp = sessionTimestamps.length ? Math.min(...sessionTimestamps) : null;
        const firstSessionDate = firstSessionTimestamp ? new Date(firstSessionTimestamp).toLocaleDateString() : 'N/A';
        const lastSessionDate = artist.lastSessionTimestamp ? new Date(artist.lastSessionTimestamp).toLocaleDateString() : 'N/A';
        const isSuperuser = sessionCount >= 10 ? 'Superuser' : '';
        const hasSubmitted = artist.hasSubmitted ? 'Ja' : 'Nein';
        const genre = Array.isArray(artist.genre) ? artist.genre.join(', ') : artist.genre;

        return {
          ...artist,
          sessionCount,
          firstSessionTimestamp,
          firstSessionDate,
          lastSessionDate,
          isSuperuser,
          hasSubmitted,
          genre,
        };
      }) : [];

      artistsList.sort((a, b) => {
        if (b.firstSessionTimestamp && a.firstSessionTimestamp) {
          return b.firstSessionTimestamp - a.firstSessionTimestamp;
        }
        if (!b.firstSessionTimestamp) return -1;
        if (!a.firstSessionTimestamp) return 1;
        return 0;
      });

      setArtists(artistsList);
      setFilteredArtists(artistsList);
      paginate(artistsList, 1);
    });
  }, []);
  
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = artists.filter(artist => 
      (artist.artistName && artist.artistName.toLowerCase().includes(query)) ||
      (artist.name && artist.name.toLowerCase().includes(query)) ||
      (artist.email && artist.email.toLowerCase().includes(query))
    );
    setFilteredArtists(filtered);
    paginate(filtered, 1);
  };

  const paginate = (artistList, pageNumber) => {
    const indexOfLastArtist = pageNumber * artistsPerPage;
    const indexOfFirstArtist = indexOfLastArtist - artistsPerPage;
    const currentArtists = artistList.slice(indexOfFirstArtist, indexOfLastArtist);
    setPaginatedArtists(currentArtists);
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredArtists.length / artistsPerPage);
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button key={i} onClick={() => paginate(filteredArtists, i)} className={i === currentPage ? 'active' : ''}>
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="list-artists-container">
      <ArtistStats />
      <ServiceStats />
      <ArtistAnalysis artists={artists} /> 

      <input
        type="text"
        placeholder="Search by artist name or email"
        value={searchQuery}
        onChange={handleSearch}
        className="search-input"
        style={{
          padding: '10px',
          margin: '20px 0',
          width: '100%',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}
      />

      <h1>Künstlerliste</h1>
      <div className="pagination-controls">
        {renderPagination()}
      </div>
      <div className="table-scroll-container">
        <table className="list-artists-table">
          <thead>
            <tr>
              <th>Edit Artist</th>
              <th>Künstlername</th>
              <th>Name</th>
              <th>Land</th>
              <th>Email</th>
              <th>Settings</th>
              <th>Premium</th>
              <th>Song Credits</th>
              <th>Collab Credits</th>
              <th>First Session (Join Date)</th>
              <th>Letztes Login</th>
              <th>Sitzungen</th>
              <th>Superuser</th>
              <th>Submitted</th>
              <th>Genre</th>
              <th>Auszeichnungen</th>
              <th>Nominiert</th>
              <th>ID</th>
              <th>Aktionen</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            {paginatedArtists.map((artist) => (
              <tr key={artist.id}>
                <td><button onClick={() => openEditModal(artist.id)}>Edit Artist</button></td>
                <td>{artist.artistName}</td>
                <td>{artist.name}</td>
                <td>{artist.country}</td>
                <td>{artist.email}</td>
                <td>{artist.hasSettings ? 'Ja' : 'Nein'}</td>
                <td>
                  <label className="switch">
                    <input 
                      type="checkbox" 
                      checked={artist.isPremium} 
                      onChange={() => togglePremiumStatus(artist)} 
                    />
                    <span className="slider round"></span>
                  </label>
                  <span
                    className="premium-status-dot"
                    style={{
                      display: 'inline-block',
                      marginLeft: '8px',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: artist.isPremium ? 'green' : 'gray',
                    }}
                  ></span>
                </td>
                <td>{artist.songCredits || 0}</td>
                <td>{artist.collabCredits || 0}</td>
                <td>{artist.firstSessionDate}</td>
                <td>{artist.lastSessionDate}</td>
                <td>{artist.sessionCount}</td>
                <td>{artist.isSuperuser}</td>
                <td>{artist.hasSubmitted}</td>
                <td>{artist.genre || 'N/A'}</td>
                <td>{artist.numberAwards}</td>
                <td>{artist.numberNominated}</td>
                <td>{artist.id}</td>
                <td><button onClick={() => openActionModal(artist)}>Aktionen</button></td>
                <td><button onClick={() => deleteArtist(artist.id)} style={{ marginLeft: "10px"}}>Löschen</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListArtists;
