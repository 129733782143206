import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importieren Sie useNavigate
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';
import '../Pages/SubmissionPage.css';
import GenerativeOrbAnimation4 from '../Utility/GenerativeOrbAnimation4';

const WelcomeScreen = () => {
    let navigate = useNavigate(); // useNavigate Hook initialisieren
  
    const handleApplicationBoxClick = (path) => {
      navigate(path); // Leitet den Benutzer zur angegebenen Seite um
    };

    return (
        <div className="submission-page">
            <GenerativeOrbAnimation4 />
            <Link to="/">
            <img src="./img/logo.svg" alt="" style={{width: '200px', marginBottom: '15px'}}/>
            </Link>
            <div className="application-box" onClick={() => handleApplicationBoxClick('/artists')}>
              <h2>Music Artists</h2>
              <p>Unlock your potential! Create an account and complete our application form to showcase your talent.</p>
              <button className="outline-button">Apply as Artist</button>
            </div>
            <div className="application-box" id="application-box-blue" onClick={() => handleApplicationBoxClick('/experts')}>
              <h2>Industry & Business</h2>
              <p>Discover the next big thing! Plese complete our application form to access fresh talent.</p>
              <button className="outline-button">Apply as Label</button>
            </div>
        </div>
      );
};

export default WelcomeScreen;
