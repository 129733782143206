import React, { useState, useEffect, useRef } from 'react';
import { ref as databaseRef, onValue, off } from 'firebase/database';
import { database } from '../firebaseConfig';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './GigDetailPreview.css';
import { motion } from 'framer-motion';
import CountryFlagSVG from '../Utility/CountryFlagEmoji';
import ReviewPreviewStars from './ReviewPreviewStars';
import PreviewVerifiedBadge from './PreviewVerifiedBadge';

const GigDetailedPreview = ({ gig, extraClass }) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [mediaItems, setMediaItems] = useState([]);
    const [imageToDisplay, setImageToDisplay] = useState(gig.artistImageUrl || "../../img/placeholder.png");
    const [reviewScore, setReviewScore] = useState(null);
    const [totalReviews, setTotalReviews] = useState(null);
    const [gigRevisions, setGigRevisions] = useState(null);
    const swiperRef = useRef(null);
    const [playing, setPlaying] = useState({});
    const [isHovering, setIsHovering] = useState({});
    const [showSoundIcon, setShowSoundIcon] = useState({});
    const [isUserOnline, setIsUserOnline] = useState(false); // Zustand für den Online-Status
    const [profileImage, setProfileImage] = useState(null); // Zustand für das Profilbild
    const [representativeName, setRepresentativeName] = useState(''); // Zustand für den representativeName

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false);
        }, 300);
    
        const galleryRef = databaseRef(database, `/Pages/${gig.pageId}/pageData/gallery`);
        const videoGalleryRef = databaseRef(database, `/Pages/${gig.pageId}/pageData/videoGallery`);
        const reviewScoreRef = databaseRef(database, `/Pages/${gig.pageId}/pageData/reviewScore`);
        const totalReviewsRef = databaseRef(database, `/Pages/${gig.pageId}/pageData/totalReviews`);
        const gigRevisionsRef = databaseRef(database, `/Pages/${gig.pageId}/pageData/offers/0/gigRevisions`);
        const userOnlineStatusRef = databaseRef(database, `/Artists/${gig.artistId}/isUserOnline`);
        const profileImgRef = databaseRef(database, `/Pages/${gig.pageId}/pageData/profileImg`);
    
        const fetchData = async () => {
            const imagesPromise = new Promise((resolve, reject) => {
                onValue(galleryRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const images = Object.values(snapshot.val()).map(url => ({ url, type: 'image' }));
                        resolve(images);
                    } else {
                        resolve([]);
                    }
                }, reject);
            });
    
            const videosPromise = new Promise((resolve, reject) => {
                onValue(videoGalleryRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const videos = Object.values(snapshot.val()).map(({ videoFile, videoThumbnail }) => ({
                            videoFile,
                            videoThumbnail,
                            type: 'video'
                        }));
                        resolve(videos);
                    } else {
                        resolve([]);
                    }
                }, reject);
            });
    
            const profileImagePromise = new Promise((resolve, reject) => {
                onValue(profileImgRef, (snapshot) => {
                    if (snapshot.exists()) {
                        resolve(snapshot.val());
                    } else {
                        resolve(null); // Setze auf null, wenn kein Gig-Bild vorhanden ist
                    }
                }, reject);
            });
    
            Promise.all([imagesPromise, videosPromise, profileImagePromise])
                .then(([images, videos, fetchedProfileImage]) => {
                    const combinedItems = [...videos, ...images];
                    setMediaItems(combinedItems);
                    setProfileImage(fetchedProfileImage || gig.artistImageUrl); // Verwende das Profilbild des Künstlers als Fallback
                    if (combinedItems.length > 0) {
                        setImageToDisplay(null); // Setze auf null, wenn Galerie vorhanden ist
                    } else if (!fetchedProfileImage && gig.artistImageUrl) {
                        setImageToDisplay(gig.artistImageUrl); // Verwende das Künstlerbild, wenn kein Gig-Bild und keine Galerie vorhanden sind
                    }
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
        };
    
        fetchData();
    
        return () => {
            off(galleryRef);
            off(videoGalleryRef);
            off(reviewScoreRef);
            off(totalReviewsRef);
            off(gigRevisionsRef);
            off(userOnlineStatusRef);
            off(profileImgRef);
        };
    }, [gig.pageId, gig.artistImageUrl, gig.artistId]);
    
    

    const handlePrevClick = (event) => {
        event.stopPropagation();
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    const handleNextClick = (event) => {
        event.stopPropagation();
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    const togglePlayPause = (event, index) => {
        event.stopPropagation();

        const videoElement = swiperRef.current.slides[index].querySelector('video');

        if (!videoElement) {
            console.error(`No video element found at index ${index}.`);
            return;
        }

        if (videoElement.paused || videoElement.ended || videoElement.muted) {
            videoElement.currentTime = 0; // Restart video
            videoElement.muted = false; // Unmute when play button is clicked
            videoElement.play().then(() => {
                setPlaying({ ...playing, [index]: true });
                setShowSoundIcon({ ...showSoundIcon, [index]: false });
            }).catch(error => {
                console.error(`Error playing video at index ${index}: ${error.message}`);
            });
        } else {
            videoElement.pause();
            setPlaying({ ...playing, [index]: false });
            setShowSoundIcon({ ...showSoundIcon, [index]: false });
        }
    };

    const handleMouseEnter = (index) => {
        const videoElement = swiperRef.current.slides[index].querySelector('video');
        if (videoElement) {
            videoElement.muted = true; // Ensure the video is muted
            videoElement.play().then(() => {
                setPlaying({ ...playing, [index]: true });
                setIsHovering({ ...isHovering, [index]: true });
                setShowSoundIcon({ ...showSoundIcon, [index]: true });
            }).catch(error => {
                console.error(`Error playing video on hover at index ${index}: ${error.message}`);
            });
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = swiperRef.current.slides[index].querySelector('video');
        if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0;
            setPlaying({ ...playing, [index]: false });
            setIsHovering({ ...isHovering, [index]: false });
            setShowSoundIcon({ ...showSoundIcon, [index]: false });
        }
    };

    const handleLinkClick = (event) => {
        // Stop all videos before navigating away
        mediaItems.forEach((_, index) => {
            const videoElement = swiperRef.current.slides[index]?.querySelector('video');
            if (videoElement && !videoElement.paused) {
                videoElement.pause();
                videoElement.currentTime = 0;
                setPlaying({ ...playing, [index]: false });
            }
        });
    };

    const getEarnAmountClass = () => {
        if (gig.payoutOption === "collaborate") {
            return "earn-amount-free";
        } else if (gig.payoutOption === "earnRoyalties") {
            return "earn-amount-percentage";
        } else {
            return "earn-amount-money";
        }
    };

    const earnAmountDisplay = gig.payoutOption === "collaborate" ? "Free" : gig.earnAmount;
    const earnAmountIcon = gig.payoutOption === "earnRoyalties" ? "../../img/percentage-discount.svg" : "../../img/dollar.svg";

    return (
        <div className={`gig-preview-container ${extraClass || ''}`} style={{ position: 'relative', padding: '0px' }}>
            <a 
                href={`/market/${gig.route}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit', zIndex: '1000' }}
                onClick={handleLinkClick}  // Stop videos before navigating
            >
                <PreviewVerifiedBadge gigId={gig.pageId} />
                <div className="gig-artist-basic-details">
                <ReviewPreviewStars reviewScore={reviewScore} totalReviews={totalReviews} />
                    {showSkeleton ? (
                        <div className="skeleton-loader-5">
                            <div className="skeleton-image-6"></div>
                        </div>
                    ) : (
                        imageToDisplay ? (
                            <img
                                src={imageToDisplay}
                                alt="Artist"
                                className="artist-image-large"
                            />
                        ) : (
                            <div className="custom-swiper-container">
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    loop={true}
                                    onSwiper={(swiper) => {
                                        swiperRef.current = swiper;
                                    }}
                                >
                                    {mediaItems.map((item, index) => (
                                        <SwiperSlide 
                                            key={index}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave(index)}
                                        >
                                            {item.type === 'image' ? (
                                                <img
                                                    src={item.url}
                                                    alt={`Gallery Image ${index}`}
                                                    className="gig-gallery-image-prev"
                                                />
                                            ) : (
                                                <div className="prev-gig-slider-video-container">
                                                    <img
                                                        src={item.videoThumbnail}
                                                        alt={`Thumbnail ${index}`}
                                                        className="gig-gallery-image-prev"
                                                        style={{ display: playing[index] ? 'none' : 'block' }}
                                                    />
                                                    <video
                                                        className="prev-gig-slide-video"
                                                        style={{ display: playing[index] ? 'block' : 'none', width: '100%' }}
                                                        controls={false}
                                                    >
                                                        <source src={item.videoFile} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                    <motion.div
                                                        className={`play-pause-btn-4 ${playing[index] ? 'is-playing' : ''}`}
                                                        onClick={(e) => togglePlayPause(e, index)}
                                                        initial="idle"
                                                        whileTap="press"
                                                    >
                                                        <motion.img
                                                            src={playing[index] ? (showSoundIcon[index] ? "../../img/volume.svg" : "../../img/pause.svg") : "../../img/play.svg"}
                                                            alt={playing[index] ? (showSoundIcon[index] ? "Sound" : "Pause") : "Play"}
                                                        />
                                                    </motion.div>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        )
                    )}
                    <div className="gig-artist-basic-bottom" style={{ display: 'flex', width: '100%', position: 'relative' }}>
                    {isUserOnline ? (
                            <div className="artist-image-small-wrapper">
                                <p>Online</p>
                                <img src={profileImage || gig.artistImageUrl || "../../img/aaa_background_1.webp"} alt={gig.artistName} className="gig-artist-image-small" />
                            </div>
                        ) : (
                            <div className="artist-image-no-wrapper">
                                <img src={profileImage || gig.artistImageUrl || "../../img/aaa_background_1.webp"} alt={gig.artistName}  className="gig-artist-image-small" />
                            </div>
                        )}
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left', marginTop: '10px'}}>
                        <p className="my-fav-artist-name">{representativeName || gig.artistName}</p>
                        <CountryFlagSVG country={gig.country} />
                        <p className="my-fav-artist-type">{gig.service}</p>
                        </div>

                    </div>
                </div>
                <div className="gig-info-details">
                    {gig.gigTitle && <p className="fav-gig-title"><span>{gig.gigTitle}</span> <br /> {gig.gigText}</p>}
                    <div className="fav-gig-details">
                        <div className="fav-gig-detail">
                            <img style={{ width: '14px' }} src={earnAmountIcon} alt="Earnings Icon" />
                            {earnAmountDisplay && (
                        <p className={`gig-earn-amount ${getEarnAmountClass()}`}>
                            {earnAmountDisplay}
                        </p>
                    )}
                        </div>
                        <div className="fav-gig-detail" style={{ marginLeft: '20px' }}>
                            <img style={{ width: '20px' }} src="../../img/rush.svg" alt="Infinity" />
                            {gig.gigTime && <p className="gig-time">{gig.gigTime} Day Delivery</p>}
                        </div>
                        <div className="fav-gig-detail" style={{ marginLeft: '20px' }}>
                            <img style={{ width: '17px' }} src="../../img/infinity.svg" alt="Revisions" />
                            {gigRevisions != null && <p className="gig-time">{gigRevisions} Revisions</p>}
                        </div>
                    </div>
                </div>
            </a>
            {mediaItems.length > 0 && (
                <>
                    <button onClick={handlePrevClick} className="custom-swiper-button-prev"><img src="../../img/down.svg" alt="" /></button>
                    <button onClick={handleNextClick} className="custom-swiper-button-next"><img src="../../img/down.svg" alt="" /></button>
                </>
            )}
        </div>
    );
};

export default GigDetailedPreview;
